import React, {useState, useEffect} from "react";
import {Dropdown, Modal, Nav, Tab} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";

export default function SwitchStreetModal(props) {
  const {show, setShow} = props;
  const [baseData, setBaseData] = useState({streets: []});
  const {user, authToken} = useSelector(state => {
    return state.auth;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if(show){
      // 获取基础数据
      axios.get('/api/management/v1/admin/street_list').then(response => {
        setBaseData(response.data);
      }).catch(error => {
        error != 401 && alert("数据初始化失败");
      });
    }
  }, [show]);
  const saveData = (values) => {
    axios.patch('/api/management/v1/admin/change_street', values).then(response => {
      dispatch(auth.actions.setCurrentStreetName(response.data.current_street_name));
      window.location.reload();
    }).catch(error => {
      error != 401 && alert("保存失败");
    });
  };

  return (<>
    <Modal
      size="lg"
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Formik
        enableReinitialize={true}
        initialValues={{street_id: baseData.current_street}}
        onSubmit={(values) => {
          saveData(values);
        }}
      >
        {({ handleSubmit, values, handleChange }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12 form-group">
                    <label>切换小区</label>
                    <div className="row">
                      {baseData.streets.map((item) => (
                        <div className="col-lg-4">
                          <label className="option">
                            <span className="option-control">
                              <span className="radio">
                                <Field type="radio" name="street_id" value={item.value} onChange={handleChange} checked={values.street_id==item.value} />
                                <span></span>
                              </span>
                            </span>
                            <span className="option-label">
                              <span className="option-head">
                                <span className="option-title">
                                  {item.label}
                                </span>
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {setShow(false)}}
                className="btn btn-light btn-elevate"
              >
                取消
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                提交
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  </>);
}
