import qs from 'qs';
import * as auth from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {
  axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Token = `${authToken}`;
      }
      //如果是post、patch，并且data数据类型是对象，并且data不是FormData类型则序列化
      if((config.method  === 'post' || config.method === 'patch') && typeof(config.data) == 'object' && config.data.constructor.name != "FormData"){
        config.data = qs.stringify(config.data);
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    response => {
      if(response.data.code == 401)
      {
        //清除redux中的用户信息
        store.dispatch(auth.actions.logout());
        window.location.href = '/auth/login';
        return Promise.reject(401);
      }
      return response.data;
    },
    err => Promise.reject(err)
  );
}



