/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import SVG from "react-inlinesvg";

export function QuickUserToggler() {
  const {user, currentStreetName} = useSelector(state => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (<>
      <div className="d-flex align-items-center svg-icon svg-icon-2x svg-icon-primary">
        <SVG
          src="/media/svg/icons/Home/Building.svg"
        />
        <h4 className="mb-0 mr-6">{currentStreetName}</h4>
      </div>

      {layoutProps.offcanvas && (<OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">个人信息</Tooltip>}
      >
        <div className="topbar-item">
          <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
            <>
              <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                {user.truename}
              </span>
              <span className="symbol symbol-35 symbol-light-success">
                  <span className="symbol-label font-size-h5 font-weight-bold">{user.truename ? user.truename[0] : ""}</span>
              </span>
            </>
          </div>
        </div>
      </OverlayTrigger>)}

      {!layoutProps.offcanvas && (<UserProfileDropdown/>)}
    </>
  );
}
