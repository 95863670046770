import React from "react";
import StreetDashboard from "../modules/Manage/_partials/StreetDashboard";
import StreetButlerDashboard from "../modules/Manage/_partials/StreetButlerDashboard";
import HqDashboard from "../modules/Manage/_partials/HqDashboard";
import HqFinanceDashboard from "../modules/Manage/_partials/HqFinanceDashboard";
import HqQualityDashboard from "../modules/Manage/_partials/HqQualityDashboard";
import {useSelector} from "react-redux";

export function DashboardPage() {
  const {user} = useSelector(state => state.auth);
  const {role} = user;

  console.log(role);
  return (<>
    {/*小区管理员面板*/}
    {["manager", "checker"].includes(role) && <StreetDashboard />}
    {/*小区管家面板*/}
    {["info_centre", "ying_manager"].includes(role) && <StreetButlerDashboard />}
    {/*总部管理员面板*/}
    {["admin"].includes(role) && <HqDashboard />}
    {/*总部财务面板*/}
    {["finance"].includes(role) && <HqFinanceDashboard />}
    {/*总部财务品控面板*/}
    {["quality"].includes(role) && <HqQualityDashboard />}
  </>);
}
