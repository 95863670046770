import React, {useEffect, useState} from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8
} from "../../../../_metronic/_partials/widgets";
import {Dropdown, Nav, Tab} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import axios from "axios";
import CheckAuthFunction from "../../../../app/modules/Manage/_partials/CheckAuthFunction";

export default function StreetDashboard() {
  const [baseData, setBaseData] = useState({
    admin_log: [],
    statistic_admin: {},
    statistic_bulletin: [],
    statistic_cost: {},
    statistic_repair: {},
    statistic_room: {},
    street_info: {}});
  useEffect(() => {
    // 获取基础数据
    axios.get('/api/management/v1/base/street_dashboard').then(response => {
      setBaseData(response.data);
    }).catch(error => {
      error != 401 && alert("数据初始化失败");
    });
  }, []);

  return (<>
    <style>
      {
      `
        .timeline.timeline-6:before{left: 130.5px}
      `}
    </style>
    <div className="row">
      <div className="col-xxl-3 order-1 order-xxl-1">
        {/*小区简介*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Header */}
          <div className="card-header border-0 justify-content-lg-between">
            <h3 className="card-title font-weight-bolder text-dark">小区基本信息</h3>
            <div className="card-toolbar">
              <div className="mt-2">
                {CheckAuthFunction("street_setting") && <a href="/manage/system/street_setting" className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">设置</a>}
              </div>
            </div>
          </div>

          {/* Body */}
          <div className="card-body pt-2">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                <span className="svg-icon svg-icon-5x svg-icon-primary">
                  <SVG
                    src={("/media/svg/icons/Home/Building.svg")}
                  />
                </span>
              </div>
              <div>
                <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                  {baseData.street_info.street_name}
                </a>
              </div>
            </div>
            <div className="py-9">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">联系电话:</span>
                <a href="#" className="text-muted text-hover-primary">{baseData.street_info.phone}</a>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">凭证打印通知电话:</span>
                <span className="text-muted">{baseData.street_info.repair_phone}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="font-weight-bold mr-2">报修分配通知电话:</span>
                <span className="text-muted">{baseData.street_info.ticket_print_phone}</span>
              </div>
            </div>
          </div>
        </div>
        {/*小区公告*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Header */}
          <div className="card-header border-0 justify-content-lg-between">
            <h3 className="card-title font-weight-bolder text-dark">小区公告</h3>
            <div className="card-toolbar">
              <div className="mt-2">
                {CheckAuthFunction("bulletin_list") && <a href="/manage/bulletins" className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">公告管理</a>}
              </div>
            </div>
          </div>

          {/* Body */}
          <div className="card-body pt-2">
            {baseData.statistic_bulletin.map(data => (
              <div className="d-flex flex-wrap align-items-top mb-2">
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                  <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
                    {data.title}
                  </a>
                  <span className="text-muted font-size-sm font-weight-bolder">
                    {data.created_at}
                  </span>
                </div>
                <div className="d-flex align-items-center py-lg-0 py-2">
                  <div className="d-flex flex-column text-right">
                  <span className="">
                    发布人:{data.operator_name}
                  </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-xxl-9 order-2 order-xxl-1">
        {/*房间统计*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Head */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            房间统计
          </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
            本小区全部房间的统计信息
          </span>
            </h3>
            <div className="card-toolbar">
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-3 pb-0">
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                <tr style={{borderBottom: "1px solid #EBEDF3"}}>
                  <th className="text-center" style={{ minWidth: "100px" }}>总住宅数</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>空置数</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>总商业数</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>空置数</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>总车库数</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>空置数</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>总车位数</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>空置数</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_room.person_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_room.empty_person_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_room.office_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_room.empty_office_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_room.carbarn_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_room.empty_carbarn_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_room.parking_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_room.empty_parking_count}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*缴费统计*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Head */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            缴费统计
          </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
            本小区近期缴费统计信息
          </span>
            </h3>
            <div className="card-toolbar">
              <a href="/manage/report/incomes" className="btn btn-primary btn-sm font-weight-bolder font-size-sm mr-3">查看更多</a>
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-3 pb-0">
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                <tr style={{borderBottom: "1px solid #EBEDF3"}}>
                  <th className="text-center" style={{ minWidth: "100px" }}>今日实收(元)</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>今日退款(元)</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>昨日实收(元)</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>昨日退款(元)</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>本月实收(元)</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>本月退款(元)</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>上月实收(元)</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>上月退款(元)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_cost.totday_total_fee}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_cost.today_refund_total_fee}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_cost.yesterday_total_fee}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_cost.yesterday_refund_total_fee}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_cost.month_total_fee}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_cost.month_refund_total_fee}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_cost.last_month_total_fee}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_cost.last_month_refund_total_fee}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*报修统计*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Head */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            报修统计
          </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
            本小区近期报修统计信息
          </span>
            </h3>
            <div className="card-toolbar">
              <a href="/manage/report/repairs" className="btn btn-primary btn-sm font-weight-bolder font-size-sm mr-3">查看更多</a>
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-3 pb-0">
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                <tr style={{borderBottom: "1px solid #EBEDF3"}}>
                  <th className="text-center" style={{ minWidth: "100px" }}>今日报修</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>今日完成</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>昨日报修</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>昨日完成</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>本月报修</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>本月完成</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>上月报修</th>
                  <th className="text-center" style={{ minWidth: "100px" }}>上月完成</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.today_reapir_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.today_complete_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.yesterday_reapir_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.yesterday_complete_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.month_reapir_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.month_complete_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.last_month_reapir_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.last_month_complete_count}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*用户统计*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Head */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            用户统计
          </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
            本小区全部用户的统计信息
          </span>
            </h3>
            <div className="card-toolbar">
              <a href="/manage/system/admins" className="btn btn-primary btn-sm font-weight-bolder font-size-sm mr-3">查看更多</a>
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-3 pb-0">
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                <tr style={{borderBottom: "1px solid #EBEDF3"}}>
                  <th className="text-center" style={{ minWidth: "150px" }}>总人数</th>
                  <th className="text-center" style={{ minWidth: "150px" }}>在职数</th>
                  <th className="text-center" style={{ minWidth: "150px" }}>冻结数</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_admin.total_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_admin.active_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_admin.freeze_count}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*我的操作日志*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Header */}
          <div className="card-header border-0 justify-content-lg-between">
            <h3 className="card-title font-weight-bolder text-dark">我的操作日志</h3>
            <div className="card-toolbar">
            </div>
          </div>

          {/* Body */}
          <div className="card-body pt-2">
            <div className="timeline timeline-6">
              {baseData.admin_log.map(data => (
                <div className="timeline-item align-items-start">
                  <div className="timeline-label" style={{width: 130}}>{data.time}</div>
                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-success"></i>
                  </div>
                  <div className="timeline-content">
                    {data.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}
