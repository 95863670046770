import React, {useEffect, useState} from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8
} from "../../../../_metronic/_partials/widgets";
import {Dropdown, Nav, Tab} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import axios from "axios";
import CheckAuthFunction from "../../../../app/modules/Manage/_partials/CheckAuthFunction";

export default function StreetDashboard() {
  const [baseData, setBaseData] = useState({
    statistic: {},
    statistic_repair: {},
    admin_log: [],
  });
  useEffect(() => {
    // 获取基础数据
    axios.get('/api/management/v1/base/finance_dashboard').then(response => {
      setBaseData(response.data);
    }).catch(error => {
      error != 401 && alert("数据初始化失败");
    });
  }, []);

  return (<>
    <style>
      {
      `
        .timeline.timeline-6:before{left: 130.5px}
      `}
    </style>
    <div className="row">
      <div className="col-xxl-4 order-1 order-xxl-1">
        {/*统计*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Header */}
          <div className="card-header border-0 justify-content-lg-start">
            <h3 className="card-title font-weight-bolder text-dark">统计</h3>
            <div className="card-toolbar">
              <div className="mt-2">
              </div>
            </div>
          </div>

          {/* Body */}
          <div className="card-body pt-0">
            {/*begin::Item*/}
            <div className="d-flex align-items-center mb-9 bg-light-warning rounded p-5">
              {/*begin::Icon*/}
              <span className="svg-icon svg-icon-warning mr-5">
                  <span className="svg-icon svg-icon-lg"><SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} /></span>
                </span>
              {/*end::Icon*/}

              {/*begin::Title*/}
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                  总小区数
                  <span className="font-size-h4 font-weight-bolder ml-10">
                      {baseData.statistic.street_count}
                    </span>
                </a>
              </div>
              {/*end::Title*/}

              {/*begin::Lable*/}
              <span className="font-weight-bolder text-warning py-1 font-size-lg">
                  {CheckAuthFunction("street_list") && <a href="/manage/streets" className="btn bg-warning btn-text-white">小区管理</a>}
                </span>
              {/*end::Lable*/}
            </div>
            {/*end::Item*/}

            {/*begin::Item*/}
            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-9">
              {/*begin::Icon*/}
              <span className="svg-icon svg-icon-success mr-5">
                  <span className="svg-icon svg-icon-lg"><SVG src={toAbsoluteUrl("/media/svg/icons/Home/Cupboard.svg")} /></span>
                </span>
              {/*end::Icon*/}

              {/*begin::Title*/}
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                  总物业公司数
                  <span className="font-size-h4 font-weight-bolder ml-10">
                      {baseData.statistic.property_count}
                    </span>
                </a>
              </div>
              {/*end::Title*/}

              {/*begin::Lable*/}
              <span className="font-weight-bolder text-success py-1 font-size-lg">
                  {CheckAuthFunction("property_list") && <a href="/manage/properties" className="btn bg-success btn-text-white">物业公司管理</a>}
                </span>
              {/*end::Lable*/}
            </div>
            {/*end::Item*/}

            {/*begin::Item*/}
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-9">
              {/*begin::Icon*/}
              <span className="svg-icon svg-icon-danger mr-5">
                  <span className="svg-icon svg-icon-lg"><SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Address-card.svg")} /></span>
                </span>
              {/*end::Icon*/}

              {/*begin::Title*/}
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                  总用户数
                  <span className="font-size-h4 font-weight-bolder ml-10">
                      {baseData.statistic.admin_count}
                    </span>
                </a>
                <span className="text-muted font-weight-bold">总冻结数：{baseData.statistic.freeze_admin_count}</span>
              </div>
              {/*end::Title*/}

              {/*begin::Lable*/}
              <span className="font-weight-bolder text-danger py-1 font-size-lg">
                  {CheckAuthFunction("admin_list") && <a href="/manage/system/admins" className="btn bg-danger btn-text-white">用户管理</a>}
                </span>
              {/*end::Lable*/}
            </div>
            {/*end::Item*/}

            {/*begin::Item*/}
            <div className="d-flex align-items-center bg-light-info rounded p-5">
              {/*begin::Icon*/}
              <span className="svg-icon svg-icon-info mr-5">
                  <span className="svg-icon svg-icon-lg"><SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} /></span>
                </span>
              {/*end::Icon*/}

              {/*begin::Title*/}
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                  总业主数
                  <span className="font-size-h4 font-weight-bolder ml-10">
                      {baseData.statistic.user_count}
                    </span>
                </a>
                <span className="text-muted font-weight-bold">总认证数：{baseData.statistic.auth_count}</span>
              </div>
              {/*end::Title*/}

            </div>
            {/*end::Item*/}
          </div>
        </div>
      </div>
      <div className="col-xxl-8 order-2 order-xxl-1">
        {/*报修统计*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Head */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            报修统计
          </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
            本月全部小区报修统计
          </span>
            </h3>
            <div className="card-toolbar">
              {CheckAuthFunction("repair_list") && <a href="/manage/report/repairs" className="btn btn-primary btn-sm font-weight-bolder font-size-sm mr-3">报修管理</a>}
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-3 pb-0">
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                <tr style={{borderBottom: "1px solid #EBEDF3"}}>
                  <th className="text-center" style={{ minWidth: "150px" }}>本月报修数</th>
                  <th className="text-center" style={{ minWidth: "150px" }}>本月完成报修数</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.month_repair_count}</span></td>
                  <td className="text-center"><span className="font-weight-bolder">{baseData.statistic_repair.month_complete_count}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*我的操作日志*/}
        <div className={`card card-custom  gutter-b`}>
          {/* Header */}
          <div className="card-header border-0 justify-content-lg-between">
            <h3 className="card-title font-weight-bolder text-dark">我的操作日志</h3>
            <div className="card-toolbar">
            </div>
          </div>

          {/* Body */}
          <div className="card-body pt-2">
            <div className="timeline timeline-6">
              {baseData.admin_log.map(data => (
                <div className="timeline-item align-items-start">
                  <div className="timeline-label" style={{width: 130}}>{data.time}</div>
                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-success"></i>
                  </div>
                  <div className="timeline-content">
                    {data.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}
