/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";

// import request from 'umi-request';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ _redux.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

// // request拦截器, 改变url 或 options.
// request.interceptors.request.use((url, options) => {
//   return {
//     url: `${url}&interceptors=yes`,
//     options: { ...options, interceptors: true },
//   };
// });
//
// // 和上一个相同
// request.interceptors.request.use(
//   (url, options) => {
//     return {
//       url: `${url}&interceptors=yes`,
//       options: { ...options, interceptors: true },
//     };
//   },
//   { global: true }
// );
//
// // response拦截器, 处理response
// request.interceptors.response.use((response, options) => {
//   const contentType = response.headers.get('Content-Type');
//   return response;
// });
//
// // 提前对响应做异常处理
// request.interceptors.response.use(response => {
//   const codeMaps = {
//     502: '网关错误。',
//     503: '服务不可用，服务器暂时过载或维护。',
//     504: '网关超时。',
//   };
//   alert(codeMaps[response.status]);
//   // message.error(codeMaps[response.status]);
//   return response;
// });
//
// // 克隆响应对象做解析处理
// request.interceptors.response.use(async response => {
//   const data = await response.clone().json();
//   if (data && data.NOT_LOGIN) {
//     alert('登录url');
//     // location.href = '登录url';
//   }
//   return response;
// });

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
