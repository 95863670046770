import React, { useEffect } from "react";
import {useField} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import { Select } from 'antd';
import 'antd/dist/antd.css';

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

export function AntSelect({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  const [field, meta, helpers] = useField(props);
  const { touched, error } = meta;
  const { data } = props;
  useEffect(() => {
    const selected_value = meta["value"] || (data.length > 0 ? data[0]["value"] : "");
    helpers.setValue(selected_value);
  }, []);
  return (
    <>
    <style>
      {
        `
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{border: none; height: 30px;}
        .ant-select{padding: initial;}
        `
      }
    </style>
      {label && <label>{label}</label>}
      <Select
        className={`${getFieldCSSClasses(touched, error)}`}
        style={{}}
        {...field}
        {...props}
      >
        {children}
        {data && data.map((item) => (<Select.Option value={item.value}>{item.label}</Select.Option>))}
      </Select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
