/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useState} from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_helpers";
import {useSelector} from "react-redux";
import SwitchStreetModal from "../../../../../app/modules/Manage/_partials/SwitchStreetModal";
import CheckAuthFunction from "../../../../../app/modules/Manage/_partials/CheckAuthFunction";

export function QuickUser() {
  const history = useHistory();
  const [switchStreetModalShow, setSwitchStreetModalShow] = useState(false);
  const {user} = useSelector(state => state.auth);

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const handleSwitchStreet = () => {
    setSwitchStreetModalShow(true);
  };

  return (<>
      <SwitchStreetModal show={switchStreetModalShow} setShow={setSwitchStreetModalShow} />

      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            用户个人资料
            <small className="text-muted font-size-sm ml-2"></small>
          </h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div
          className="offcanvas-content pr-5 mr-n5"
        >
          <div className="d-flex align-items-center mt-5">
            <div
              className="symbol symbol-100 mr-5"
            >
              <div className="symbol-label" style={{
                backgroundImage: `url(/media/logos/advert1.png)`
              }}/>
              <i className="symbol-badge bg-success"/>
            </div>
            <div className="d-flex flex-column">
              <a
                href="#"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {user.truename}
              </a>
              <div className="text-muted mt-1">{user.role_name}</div>
              <div className="navi mt-2">
                <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>退出登录</button>
                <a className="btn btn-primary btn-bold ml-4" href="/manage/settings/password">修改密码</a>
                {CheckAuthFunction("change_street") && <button className="btn btn-primary btn-bold ml-2" onClick={handleSwitchStreet}>切换小区</button>}
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
              Sign Out
            </Link> */}
            </div>
          </div>


        </div>
      </div>
    </>
  );
}
